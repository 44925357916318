import { createSelector } from 'reselect'
import get from 'lodash/get'
import appConstants from '../../../custom/config/appConstants'
import { formatData, formatDataShort, formatRowId, formatUserName } from '../common/selectors'
import { capitalizeString } from '../../services/utils'
import uuid from 'react-uuid'

export const getUsersState = createSelector(
    [
        (state) => {
            return get(state, appConstants.store.routes.users)
        },
    ],
    (usersState) => usersState,
)

export const getUsersIsLoading = createSelector([getUsersState], (state) => {
    try {
        return state.isLoading
    } catch (e) {
    }
    return false
})

export const getUsersPayrollEmployeesData = createSelector([getUsersState], (state) => {
    try {
        return state.payrollEmployees ? state.payrollEmployees : []
    } catch (e) {
    }
    return []
})

export const getUsersPayrollEmployees = createSelector([getUsersPayrollEmployeesData], (employees) => {
    try {
        if (employees) {
            return employees.map((emp) => {
                return {
                    ...emp,
                    otherNames: capitalizeString(`${emp?.otherNames ? emp?.otherNames : ''}`.toLowerCase()),
                    lastName: capitalizeString(`${emp?.lastName ? emp?.lastName : ''}`.toLowerCase()),
                }
            })
        }
    } catch (e) {
    }
    return []
})

export const getUsersPayrollEmployeesF = createSelector([getUsersPayrollEmployeesData], (employees) => {
    try {
        if (employees) {
            return employees.map((emp) => {
                return {
                    ...emp,
                    actions: '',
                    rowId: uuid(),
                    otherNames: capitalizeString(`${emp?.otherNames ? emp?.otherNames : ''}`.toLowerCase()),
                    lastName: capitalizeString(`${emp?.lastName ? emp?.lastName : ''}`.toLowerCase()),
                    employeeName: `${emp?.otherNames ? emp.otherNames : ''} ${
                        emp?.lastName ? emp.lastName : ''
                    }`.toLowerCase(),
                    idCardNumber: emp.idCardNumber,
                    iban: emp.wpsIban || '***',
                    refId: emp.refId || '-',
                    position: emp.position || '-',
                    department: emp.department || '-',
                    createdAt: emp.prePrinted ? formatDataShort({ createdAt: emp.cardGeneratedAt }) : formatDataShort({ createdAt: emp.createdAt }),
                    status: emp.status || '-',
                }
            })
        }
    } catch (e) {}
    return []
})


export const getUsersPayrollEmployeesSearch = createSelector([getUsersState], (state) => {
    try {
        return state.search ? state.search : ''
    } catch (e) {
    }
    return ''
})
export const getUsersPayrollEmployeesPage = createSelector([getUsersState], (state) => {
    try {
        return state.page ? state.page : null
    } catch (e) {
    }
    return null
})
export const getUsersPayrollEmployeesNumOfPages = createSelector([getUsersState], (state) => {
    try {
        return state.numOfPages ? state.numOfPages : 0
    } catch (e) {
    }
    return 0
})


export const getUsersAllRaw = createSelector([getUsersState], (state) => {
    try {
        return state.users
    } catch (e) {
    }
    return []
})

export const getUsersUserLimits = createSelector([(user) => user], (user) => {
    try {
        if (user.limits) {
            return user.limits
        } else if (user.limit) {
            return [
                {
                    isActive: true,
                    period: user.limit.limitType,
                    limit: user.limit.limitValue,
                },
            ]
        }
    } catch (e) {
    }
    return []
})

export const getUsersUserActiveLimit = createSelector([getUsersUserLimits], (limits) => {
    try {
        return limits.find((l) => l.isActive)
    } catch (e) {
    }
    return null
})

export const getUsersUserActiveLimitText = createSelector([getUsersUserActiveLimit], (limit) => {
    try {
        if (limit) {
            return `${limit.period} - ${limit.limit}`
        }
    } catch (e) {
    }
    return 'N/A'
})

export const getUsersAllUsers = createSelector([getUsersAllRaw], (users) => {
    try {
        return users.map((user, index) => {
            return {
                ...user,
                id: index + 1,
                name: formatUserName(user),
                otherNames: capitalizeString(`${user?.otherNames ? user?.otherNames : ''}`.toLowerCase()),
                lastName: capitalizeString(`${user?.lastName ? user?.lastName : ''}`.toLowerCase()),
                limitText: getUsersUserActiveLimitText(user),
                date: formatData(user.createdAt),
                rowId: formatRowId(user),
            }
        })
    } catch (e) {
    }
    return []
})

export const getUsersUserDetails = createSelector([getUsersState], (state) => {
    try {
        return state.userDetails
    } catch (e) {
    }
    return null
})
export const getUsersFileCounts = createSelector([getUsersState], (state) => {
    try {
        return state.fileCounts
    } catch (e) {
    }
    return null
})

export const getUsersFileCountsTotal = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.totalFiles ? state.totalFiles : 0
    } catch (e) {
    }
    return 0
})
export const getUsersFileCountsAuth = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.authFiles ? state.authFiles : 0
    } catch (e) {
    }
    return 0
})
export const getUsersFileCountsValidate = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.validateFiles ? state.validateFiles : 0
    } catch (e) {
    }
    return 0
})
export const getUsersFileCountsAuthBulk = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.authFilesBulk ? state.authFilesBulk : 0
    } catch (e) {
    }
    return 0
})
export const getUsersFileCountsAuthIndividual = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.authFilesIndividual ? state.authFilesIndividual : 0
    } catch (e) {
    }
    return 0
})

export const getUsersFileCountsAuthWPSBulk = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.authWPSFilesBulk ? state.authWPSFilesBulk : 0
    } catch (e) {
    }
    return 0
})

export const getUsersFileCountsValidateFilesBulk = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.validateFilesBulk ? state.validateFilesBulk : 0
    } catch (e) {
    }
    return 0
})
export const getUsersFileCountsValidateFilesIndividual = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.validateFilesIndividual ? state.validateFilesIndividual : 0
    } catch (e) {
    }
    return 0
})

export const getUsersFileCountsValidateWPSFilesBulk = createSelector([getUsersFileCounts], (state) => {
    try {
        return state.validateWPSFilesBulk ? state.validateWPSFilesBulk : 0
    } catch (e) {
    }
    return 0
})

export const getUsersUserPromo = createSelector([getUsersState], (state) => {
    try {
        return state.userPromo
    } catch (e) {
    }
    return null
})
