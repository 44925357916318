import types from '../actions/types'

const initialState = {
    isLoggedIn: false,
    isLoading: true,
    createpaymentBulk: [],
    validateIndividualPayment: [],
    validateBulkPayment: [],
    validateWpsPayment: [],
    authBulkPayment: [],
    authIndividualPayment: [],
    authWpsPayment: [],
    loadCorporate: [],
    balance: '',
    downloadFile: [],
    wpsStatus: [],
    statusReport: [],
    viewPyaments: [],
    totalbalanceTransaction: [],
    totalEmployeeGraph: [],
    numberOfPayments: [],
    valueOfPayments: [],
    errors: null,
}

export default function(state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case types.CREATE_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                createpaymentBulk: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.CREATE_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.CREATE_BULK_PAYMENT:
            return {
                ...state,
                isLoading: true,
            }

            break
        case types.CREATE_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                payment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.CREATE_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.CREATE_INDIVIDUAL_VALIDATE_PAYMENT:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.CREATE_INDIVIDUAL_VALIDATE_PAYMENT_SUCCESS:
            return {
                ...state,
                createpaymentBulk: payload,
                isLoading: false,
                errors: null,
            }
        case types.CREATE_INDIVIDUAL_VALIDATE_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_VALIDATE_INDIVIDUAL:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_VALIDATE_INDIVIDUAL_SUCCESS:
            return {
                ...state,
                validateIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
        case types.FETCH_VALIDATE_INDIVIDUAL_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_VALIDATE_BULK:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_VALIDATE_BULK_SUCCESS:
            return {
                ...state,
                validateBulkPayment: payload,
                isLoading: false,
                errors: null,
            }
        case types.FETCH_VALIDATE_BULK_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_VALIDATE_WPS:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_VALIDATE_WPS_SUCCESS:
            return {
                ...state,
                validateWpsPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_VALIDATE_WPS_FAILED:
            return {
                ...state,
                isLoading: false,
            }
            break
        case types.FETCH_AUTHORIZE_BULK_PAYMENT:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_AUTHORIZE_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                authBulkPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_AUTHORIZE_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                authIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_AUTHORIZE_WPS_PAYMENT:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_AUTHORIZE_WPS_PAYMENT_SUCCESS:
            return {
                ...state,
                authWpsPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_AUTHORIZE_WPS_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.VALIDATE_VALID_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                validateBulkPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.VALIDATE_VALID_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
            }
            break
        case types.REJECT_VALIDATE_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                validateBulkPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.REJECT_VALIDATE_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break

        case types.VALIDATE_VALID_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                validateIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.VALIDATE_VALID_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
            }
            break
        case types.REJECT_VALIDATE_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                validateIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.REJECT_VALIDATE_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.CREATE_WPS_BULK_PAYMENT:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.CREATE_WPS_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                createpaymentBulk: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.CREATE_WPS_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break

        //   case types.REJECT_AUTHORIZE_BULK_PAYMENT_SUCCESS:
        //   return {
        //     ...state,
        //     authBulkPayment: payload,
        //     isLoading: false,
        //   }
        //   break;
        // case types.REJECT_AUTHORIZE_BULK_PAYMENT_FAILED:
        //   return {
        //     ...state,
        //     isLoading: false,
        //   }
        //   break;

        case types.VALIDATE_VALID_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                validateIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.VALIDATE_VALID_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.REJECT_VALIDATE_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                validateIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.REJECT_VALIDATE_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.REJECT_AUTHORIZE_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                authBulkPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.REJECT_AUTHORIZE_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                authIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.CREATE_LOAD_CORPORATE_ACCOUNT:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.CREATE_LOAD_CORPORATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loadCorporate: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.CREATE_LOAD_CORPORATE_ACCOUNT_FAILED:
        case types.REJECT_AUTHORIZE_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                authBulkPayment: payload,
                isLoading: false,
            }
            break
        case types.REJECT_AUTHORIZE_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                authIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break

        case types.FETCH_BALANCE:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_BALANCE_SUCCESS:
            return {
                ...state,
                balance: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_BALANCE_FAILED:
            return {
                ...state,
                isLoading: false,
            }
            break
        case types.APPROVE_AUTHORIZE_BULK_PAYMENT_SUCCESS:
            return {
                ...state,
                authBulkPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.APPROVE_AUTHORIZE_BULK_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.APPROVE_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS:
            return {
                ...state,
                authIndividualPayment: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.APPROVE_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_DOWNLOAD_FILE:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_DOWNLOAD_FILE_SUCCESS:
            return {
                ...state,
                downloadFile: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_DOWNLOAD_FILE_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_WPS_STATUS:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_WPS_STATUS_SUCCESS:
            return {
                ...state,
                wpsStatus: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_WPS_STATUS_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_STATUS_REPORT:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_STATUS_REPORT_SUCCESS:
            return {
                ...state,
                statusReport: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_STATUS_REPORT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break

        case types.FETCH_DOWNLOAD_TRANSACTION_FILE_SUCCESS:
            return {
                ...state,
                statusReport: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_DOWNLOAD_TRANSACTION_FILE_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
        case types.FETCH_VIEW_PAYMENTS:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_VIEW_PAYMENTS_SUCCESS:
            return {
                ...state,
                viewPyaments: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_VIEW_PAYMENTS_FAILED:
            return {
                ...state,
                isLoading: false,
            }
        case types.FETCH_TRANSACTION_STATEMENT:
            return {
                ...state,
                isLoading: true,
            }
        case types.FETCH_TRANSACTION_STATEMENT_SUCCESS:
            return {
                ...state,
                statusReport: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_TRANSACTION_STATEMENT_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_TOTAL_BALANCE_TRANSACTION:
            return {
                ...state,
                isLoading: true,
            }
        case types.FETCH_TOTAL_BALANCE_TRANSACTION_SUCCESS:
            return {
                ...state,
                totalbalanceTransaction: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_TOTAL_BALANCE_TRANSACTION_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_TOTAL_EMPLOYEE_GRAPH:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_TOTAL_EMPLOYEE_GRAPH_SUCCESS:
            return {
                ...state,
                totalEmployeeGraph: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_TOTAL_EMPLOYEE_GRAPH_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_NUMBER_OF_PAYMENTS_GRAPH:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_NUMBER_OF_PAYMENTS_GRAPH_SUCCESS:
            return {
                ...state,
                numberOfPayments: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_NUMBER_OF_PAYMENTS_GRAPH_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        case types.FETCH_VALUE_OF_PAYMENTS_GRAPH:
            return {
                ...state,
                isLoading: true,
            }
            break
        case types.FETCH_VALUE_OF_PAYMENTS_GRAPH_SUCCESS:
            return {
                ...state,
                valueOfPayments: payload,
                isLoading: false,
                errors: null,
            }
            break
        case types.FETCH_VALUE_OF_PAYMENTS_GRAPH_FAILED:
            return {
                ...state,
                isLoading: false,
                errors: payload,
            }
            break
        default:
            return state
    }
}
