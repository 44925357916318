import { baseApi } from '../../apis'

const getPayments = ({ url, page, search }) => {
    const params = []

    if (page) {
        params.push(`page=${page}`)
    }

    if (search) {
        params.push(`search=${search}`)
    }

    const res_url = params.length ? `${url}?${params.join('&')}` : url

    return baseApi.get({ url: res_url })
}

const paymentApi = {
    getBalance() {
        return baseApi.get({ url: '/transaction/balance/portal' })
    },
    getWpsStatus({ page, search }) {
        return getPayments({ url: `/files/status/WPS`, page, search })
    },
    getStatusReport({ page, search }) {
        return getPayments({ url: `/transaction`, page, search })
    },
    getAuthBulkPayments({ page, search }) {
        return getPayments({ url: `/file/authorize/bulk`, page, search })
    },
    getAuthIndividualPayments({ page, search }) {
        return getPayments({ url: `/file/authorize/individual`, page, search })
    },
    getAuthWpsPayments({ page, search }) {
        return getPayments({ url: `/file/authorize/bulk/wps`, page, search })
    },
    getValidateBulkPayment({ page, search }) {
        return getPayments({ url: `/file/validate/bulk`, page, search })
    },
    getValidateIndividualPayment({ page, search }) {
        return getPayments({ url: `/file/validate/individual`, page, search })
    },
    getValidateWpsPayments({ page, search }) {
        return getPayments({ url: `/file/validate/bulk/wps`, page, search })
    },

    approveAuthBulkPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/generateverificationcode`, body: {} })
    },
    generateSMSMultipleAuthBulkPayment(fileIds) {
        return baseApi.post({ url: `/file/generateverificationcode/bulk`, body: { ids: fileIds } })
    },
    approveWithSmsCodeBulk({ sid, smsCode }) {
        return baseApi.post({ url: `/file/${sid}/bulk`, body: { smsCode } })
    },
    approveMultpleWithSmsCodeBulk({ sids, smsCode }) {
        return baseApi.post({ url: `/file/auth/bulk`, body: { smsCode: smsCode, ids: sids } })
    },
    rejectAuthorizePayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/rejectBulk`, body: {} })
    },
    approveAuthIndividualPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/generateverificationcode`, body: {} })
    },
    approveAuthIndividualPaymentSmsCode({ aId, smsCode }) {
        return baseApi.post({ url: `/file/${aId}/individual`, body: { smsCode } })
    },
    rejectAuthorizeIndividualPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/rejectIndividual`, body: {} })
    },
    approveAuthWPSPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/authWPSbulk`, body: {} })
    },
    rejectAuthWPSPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/rejectWPSBulk`, body: {} })
    },
    validateValidBulkPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/validateBulk`, body: {} })
    },
    rejectValidateBulkPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/rejectBulk`, body: {} })
    },
    validValidateIndividualPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/validateIndividual`, body: {} })
    },
    rejectValidateIndividualPayment(fileId) {
        return baseApi.post({ url: `/file/${fileId}/rejectIndividual`, body: {} })
    },
    validValidateWpsBulk(fileId) {
        return baseApi.post({ url: `/file/${fileId}/validateWPSBulk`, body: {} })
    },
    rejectValidateWpsBulk(fileId) {
        return baseApi.post({ url: `/file/${fileId}/rejectWPSBulk`, body: {} })
    },
    getViewPayments({ page, search }) {
        const params = []

        if (page) {
            params.push(`page=${page}`)
        }

        if (search) {
            if(search?.end){
                params.push(`endDate=${search?.end}&startDate=${search?.start}`)
            } else {
                params.push(`search=${search}`)
            }
        }
        const url = `/users/transactions`

        const res_url = params.length ? `${url}?${params.join('&')}` : url
        return baseApi.get({ url: res_url })
    },

}

export default paymentApi
