export default {
    // auth actions
    LOGGED_IN: 'LOGGED_IN',
    LOGIN_FAILED: 'LOGIN_FAILED',
    REGISTERED: 'REGISTERED',
    REGISTER_FAILED: 'REGISTER_FAILED',
    LOGGED_OUT: 'LOGGED_OUT',
    LOAD_USER: 'LOAD_USER',
    FETCH_EMPLOYEES: 'FETCH_EMPLOYEES',
    FETCH_EMPLOYEES_SUCCESS: 'FETCH_EMPLOYEES_SUCCESS',
    REMOVE_EMPLOYEE_SUCCESS: 'REMOVE_EMPLOYEE_SUCCESS',
    FETCH_EMPLOYEES_FAILD: 'FETCH_EMPLOYEES_FAILD',
    SMS_VERIFICATION_FAILD: 'SMS_VERIFICATION_FAILD',
    FETCH_USER: 'FETCH_USER',
    FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
    FETCH_USER_FAILED: 'FETCH_USER_FAILED',
    FETCH_COMPANY_PROFILE: 'FETCH_COMPANY_PROFILE',
    FETCH_COMPANY_PROFILE_SUCCESS: 'FETCH_COMPANY_PROFILE_SUCCESS',
    FETCH_COMPANY_PROFILE_FAILED: 'FETCH_COMPANY_PROFILE_FAILED',
    FETCH_SEARCH_RESULT: 'FETCH_SEARCH_RESULT',
    FETCH_USER_LIST: 'FETCH_USER_LIST',
    FETCH_USER_LIST_SUCCESS: 'FETCH_USER_LIST_SUCCESS',
    FETCH_USER_LIST_FAILED: 'FETCH_USER_LIST_FAILED',
    FETCH_ROLES: 'FETCH_ROLES',
    FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
    FETCH_ROLES_FAILED: 'FETCH_ROLES_FAILED',
    FETCH_ROLES_UPDATE: 'FETCH_ROLES_UPDATE',
    FETCH_ROLES_UPDATE_SUCCESS: 'FETCH_ROLES_UPDATE_SUCCESS',
    FETCH_ROLES_UPDATE_FAILED: 'FETCH_ROLES_UPDATE_FAILED',
    UPDATE_ROLES_SUCCESS: 'UPDATE_ROLES_SUCCESS',
    UPDATE_ROLES_FAILED: 'UPDATE_ROLES_FAILED',
    UPDATE_ROLES_STATUS_SUCCESS: 'UPDATE_ROLES_STATUS',
    UPDATE_ROLES_STATUS_FAILED: 'UPDATE_ROLES_FAILED',
    UPDATE_PERMISSION_SUCCESS: ' UPDATE_PERMISSION_SUCCESS',
    UPDATE_PERMISSION_FAILED: ' UPDATE_PERMISSION_FAILED',
    CREATE_INDIVIDUAL_PAYMENT: 'CREATE_INDIVIDUAL_PAYMENT',
    CREATE_INDIVIDUAL_PAYMENT_SUCCESS: 'CREATE_INDIVIDUAL_PAYMENT_SUCCESS',
    CREATE_INDIVIDUAL_PAYMENT_FAILED: 'CREATE_INDIVIDUAL_PAYMENT_FAILED',
    CREATE_BULK_PAYMENT: 'CREATE_BULK_PAYMENT',
    CREATE_BULK_PAYMENT_SUCCESS: 'CREATE_BULK_PAYMENT_SUCCESS',
    CREATE_BULK_PAYMENT_FAILED: 'CREATE_BULK_PAYMENT_FAILED',
    CREATE_INDIVIDUAL_VALIDATE_PAYMENT: 'CREATE_BULK_PAYMENT',
    CREATE_INDIVIDUAL_VALIDATE_PAYMENT_SUCCESS: 'CREATE_BULK_PAYMENT_SUCCESS',
    CREATE_INDIVIDUAL_VALIDATE_PAYMENT_FAILED: 'CREATE_BULK_PAYMENT_FAILED',
    FETCH_VALIDATE_INDIVIDUAL: 'FETCH_VALIDATE_INDIVIDUAL',
    FETCH_VALIDATE_INDIVIDUAL_SUCCESS: 'FETCH_VALIDATE_INDIVIDUAL_SUCCESS',
    FETCH_VALIDATE_INDIVIDUAL_FAILED: 'FETCH_VALIDATE_INDIVIDUAL_FAILED',
    FETCH_VALIDATE_BULK: 'FETCH_VALIDATE_BULK',
    FETCH_VALIDATE_BULK_SUCCESS: 'FETCH_VALIDATE_BULK_SUCCESS',
    FETCH_VALIDATE_BULK_FAILED: 'FETCH_VALIDATE_BULK_FAILED',
    FETCH_VALIDATE_WPS: 'FETCH_VALIDATE_WPS',
    FETCH_VALIDATE_WPS_SUCCESS: 'FETCH_VALIDATE_WPS_SUCCESS',
    FETCH_VALIDATE_WPS_FAILED: 'FETCH_VALIDATE_WPS_FAILED',



    FETCH_AUTHORIZE_BULK_PAYMENT: 'FETCH_AUTHORIZE_BULK_PAYMENT',
    FETCH_AUTHORIZE_BULK_PAYMENT_SUCCESS: 'FETCH_AUTHORIZE_BULK_PAYMENT_SUCCESS',
    FETCH_AUTHORIZE_BULK_PAYMENT_FAILED: 'FETCH_AUTHORIZE_BULK_PAYMENT_FAILED',
    FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT: 'FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT',
    FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS: 'FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS',
    FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED: 'FETCH_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED',
    FETCH_AUTHORIZE_WPS_PAYMENT: 'FETCH_AUTHORIZE_WPSL_PAYMENT',
    FETCH_AUTHORIZE_WPS_PAYMENT_SUCCESS: 'FETCH_AUTHORIZE_WPS_PAYMENT_SUCCESS',
    FETCH_AUTHORIZE_WPS_PAYMENT_FAILED: 'FETCH_AUTHORIZE_WPS_PAYMENT_FAILED',



    VALIDATE_VALID_BULK_PAYMENT_SUCCESS: 'VALIDATE_VALID_BULK_PAYMENT_SUCCESS',
    VALIDATE_VALID_BULK_PAYMENT_FAILED: 'VALIDATE_VALID_BULK_PAYMENT_FAILED',
    REJECT_VALIDATE_BULK_PAYMENT_SUCCESS: 'REJECT_VALIDATE_BULK_PAYMENT_SUCCESS',
    REJECT_VALIDATE_BULK_PAYMENT_FAILED: 'REJECT_VALIDATE_BULK_PAYMENT_FAILED',
    VALIDATE_VALID_INDIVIDUAL_PAYMENT_SUCCESS: 'VALIDATE_VALID_INDIVIDUAL_PAYMENT_SUCCESS',
    VALIDATE_VALID_INDIVIDUAL_PAYMENT_FAILED: 'VALIDATE_VALID_INDIVIDUAL_PAYMENT_FAILED',
    VALIDATE_VALID_WPS_PAYMENT_SUCCESS: 'VALIDATE_VALID_INDIVIDUAL_PAYMENT_SUCCESS',
    VALIDATE_VALID_WPS_PAYMENT_FAILED: 'VALIDATE_VALID_INDIVIDUAL_PAYMENT_FAILED',
    REJECT_VALIDATE_INDIVIDUAL_PAYMENT_SUCCESS: 'REJECT_VALIDATE_INDIVIDUAL_PAYMENT_SUCCESS',
    REJECT_VALIDATE_INDIVIDUAL_PAYMENT_FAILED: 'REJECT_VALIDATE_INDIVIDUAL_PAYMENT_FAILED',
    CREATE_LOAD_CORPORATE_ACCOUNT: 'CREATE_LOAD_CORPORATE_ACCOUNT',
    CREATE_LOAD_CORPORATE_ACCOUNT_SUCCESS: 'CREATE_LOAD_CORPORATE_ACCOUNT_SUCCESS',
    CREATE_LOAD_CORPORATE_ACCOUNT_FAILED: 'CREATE_LOAD_CORPORATE_ACCOUNT_FAILED',
    CREATE_WPS_BULK_PAYMENT: 'CREATE_BULK_PAYMENT',
    CREATE_WPS_BULK_PAYMENT_SUCCESS: 'CREATE_BULK_PAYMENT_SUCCESS',
    CREATE_WPS_BULK_PAYMENT_FAILED: 'CREATE_BULK_PAYMENT_FAILED',
    FETCH_WPS_STATUS: 'FETCH_WPS_STATUS',
    FETCH_WPS_STATUS_SUCCESS: 'FETCH_WPS_STATUS_SUCCESS',
    FETCH_WPS_STATUS_FAILED: 'FETCH_WPS_STATUS_FAILED',
    FETCH_STATUS_REPORT: 'FETCH_STATUS_REPORT',
    FETCH_STATUS_REPORT_SUCCESS: 'FETCH_STATUS_REPORT_SUCCESS',
    FETCH_STATUS_REPORT_FAILED: 'FETCH_STATUS_REPORT_FAILED',
    REJECT_AUTHORIZE_BULK_PAYMENT_SUCCESS: 'REJECT_AUTHORIZE_BULK_PAYMENT_SUCCESS',
    REJECT_AUTHORIZE_BULK_PAYMENT_FAILED: 'REJECT_AUTHORIZE_BULK_PAYMENT_FAILED',
    REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS: 'REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS',
    REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED: 'REJECT_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED',
    FETCH_BALANCE: 'FETCH_BALANCE',
    FETCH_BALANCE_SUCCESS: 'FETCH_BALANCE_SUCCESS',
    FETCH_BALANCE_FAILED: 'FETCH_BALANCE_FAILED',
    APPROVE_AUTHORIZE_BULK_PAYMENT_SUCCESS: 'APPROVE_AUTHORIZE_BULK_PAYMENT_SUCCESS',
    APPROVE_AUTHORIZE_BULK_PAYMENT_FAILED: 'APPROVE_AUTHORIZE_BULK_PAYMENT_FAILED',
    APPROVE_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS: 'APPROVE_AUTHORIZE_INDIVIDUAL_PAYMENT_SUCCESS',
    APPROVE_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED: 'APPROVE_AUTHORIZE_INDIVIDUAL_PAYMENT_FAILED',
    FETCH_DOWNLOAD_FILE: 'FETCH_DOWNLOAD_FILE',
    FETCH_DOWNLOAD_FILE_SUCCESS: 'FETCH_DOWNLOAD_FILE_SUCCESS',
    FETCH_DOWNLOAD_FILE_FAILED: 'FETCH_DOWNLOAD_FILE_FAILED',
    FETCH_VIEW_PAYMENTS: 'FETCH_VIEW_PAYMENTS',
    FETCH_VIEW_PAYMENTS_SUCCESS: 'FETCH_VIEW_PAYMENTS_SUCCESS',
    FETCH_VIEW_PAYMENTS_FAILED: 'FETCH_VIEW_PAYMENTS_FAILED',
    FETCH_DOWNLOAD_TRANSACTION_FILE: 'FETCH_DOWNLOAD_TRANSACTION_FILE',
    FETCH_DOWNLOAD_TRANSACTION_FILE_SUCCESS: 'FETCH_DOWNLOAD_TRANSACTION_FILE_SUCCESS',
    FETCH_DOWNLOAD_TRANSACTION_FILE_FAILED: 'FETCH_DOWNLOAD_TRANSACTION_FILE_FAILED:',
    FETCH_TRANSACTION_STATEMENT: 'FETCH_TRANSACTION_STATEMENT',
    FETCH_TRANSACTION_STATEMENT_SUCCESS: 'FETCH_TRANSACTION_STATEMENT_SUCCESS',
    FETCH_TRANSACTION_STATEMENT_FAILED: 'FETCH_TRANSACTION_STATEMENT_FAILED',
    FETCH_TOTAL_BALANCE_TRANSACTION: 'FETCH_TOTAL_BALANCE_TRANSACTION',
    FETCH_TOTAL_BALANCE_TRANSACTION_SUCCESS: 'FETCH_TOTAL_BALANCE_TRANSACTION_SUCCESS',
    FETCH_TOTAL_BALANCE_TRANSACTION_FAILED: 'FETCH_TOTAL_BALANCE_TRANSACTION_FAILED',

    FETCH_TOTAL_EMPLOYEE_GRAPH: 'FETCH_TOTAL_EMPLOYEE_GRAPH',
    FETCH_TOTAL_EMPLOYEE_GRAPH_SUCCESS: 'FETCH_TOTAL_EMPLOYEE_GRAPH_SUCCESS',
    FETCH_TOTAL_EMPLOYEE_GRAPH_FAILED: 'FETCH_TOTAL_EMPLOYEE_GRAPH_FAILED',
    FETCH_NUMBER_OF_PAYMENTS_GRAPH: 'FETCH_NUMBER_OF_PAYMENTS_GRAPH',
    FETCH_NUMBER_OF_PAYMENTS_GRAPH_SUCCESS: 'FETCH_NUMBER_OF_PAYMENTS_GRAPH_SUCCESS',
    FETCH_NUMBER_OF_PAYMENTS_GRAPH_FAILED: 'FETCH_NUMBER_OF_PAYMENTS_GRAPH_FAILED',
    FETCH_VALUE_OF_PAYMENTS_GRAPH: 'FETCH_VALUE_OF_PAYMENTS_GRAPH',
    FETCH_VALUE_OF_PAYMENTS_GRAPH_SUCCESS: 'FETCH_VALUE_OF_PAYMENTS_GRAPH_SUCCESS',
    FETCH_VALUE_OF_PAYMENTS_GRAPH_FAILED: ' FETCH_VALUE_OF_PAYMENTS_GRAPH_FAILED',

    DOWNLOAD_USER_AVATAR_TRIGGER: 'DOWNLOAD_USER_AVATAR_TRIGGER',
    DOWNLOAD_USER_AVATAR_SUCCESS: 'DOWNLOAD_USER_AVATAR_SUCCESS',
    DOWNLOAD_USER_AVATAR_FAILED: 'DOWNLOAD_USER_AVATAR_FAILED',

    UPDATE_USER_AVATAR_TRIGGER: 'UPDATE_USER_AVATAR_TRIGGER',
    UPDATE_USER_AVATAR_SUCCESS: 'UPDATE_USER_AVATAR_SUCCESS',
    UPDATE_USER_AVATAR_FAILED: 'UPDATE_USER_AVATAR_FAILED',

    ENFORCE_PASSWORD_RESET: 'ENFORCE_PASSWORD_RESET',
}
