import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import * as ACTIONS from './actions'

import { paymentApi } from '../../../../custom/services/apis'
import { getFileCounts } from '../../users/actions'
import { getPaymentsPage, getPaymentsSearch } from './selectors'
import appConstants from '../../../config/appConstants'


export function* getBalanceHandler(action) {
    yield put(ACTIONS.getBalance.request(action.payload))
    try {
        const data = yield call(paymentApi.getBalance)

        yield put(ACTIONS.getBalance.success({ data }))
    } catch (e) {
        yield put(ACTIONS.getBalance.failure(e))
    }
}

export function* getWpsStatusHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getWpsStatus.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.WpsStatus)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.WpsStatus)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getWpsStatus, query)

        yield put(ACTIONS.getWpsStatus.success({ data, ...query }))
    } catch (e) {
        yield put(ACTIONS.getWpsStatus.failure(e))
    }
}

export function* getStatusReportHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getStatusReport.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.StatusRepost)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.StatusRepost)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getStatusReport, query)

        yield put(ACTIONS.getStatusReport.success({ data, ...query }))
    } catch (e) {
        yield put(ACTIONS.getStatusReport.failure(e))
    }
}

export function* getViewPaymentsHandler(action) {
    const { page, search, text } = action.payload
    yield put(ACTIONS.getViewPayments.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.ViewPayments)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.ViewPayments)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getViewPayments, query)

        yield put(ACTIONS.getViewPayments.success({ data, text, ...query }))
    } catch (e) {
        yield put(ACTIONS.getViewPayments.failure(e))
    }
}

export function* getAuthBulkPaymentsHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getAuthBulkPayments.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.AuthBulkPayments)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.AuthBulkPayments)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getAuthBulkPayments, query)
        yield put(ACTIONS.getAuthBulkPayments.success({ data, ...query }))

    } catch (e) {
        console.log(e)
        yield put(ACTIONS.getAuthBulkPayments.failure(e))
    }
}

export function* getAuthIndividualPaymentsHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getAuthIndividualPayments.request(action.payload))
    try {

        const currPage = yield select(getPaymentsPage, appConstants.payments.type.AuthIndividualPayments)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.AuthIndividualPayments)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getAuthIndividualPayments, query)
        yield put(ACTIONS.getAuthIndividualPayments.success({ data, ...query }))

    } catch (e) {
        console.log(e)
        yield put(ACTIONS.getAuthIndividualPayments.failure(e))
    }
}

export function* getAuthWpsPaymentsHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getAuthWpsPayments.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.AuthWpsPayments)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.AuthWpsPayments)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getAuthWpsPayments, query)
        yield put(ACTIONS.getAuthWpsPayments.success({ data, ...query }))

    } catch (e) {
        yield put(ACTIONS.getAuthWpsPayments.failure(e))
    }
}

export function* getValidateBulkPaymentHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getValidateBulkPayment.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.ValidateBulkPayment)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.ValidateBulkPayment)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getValidateBulkPayment, query)
        yield put(ACTIONS.getValidateBulkPayment.success({ data, ...query }))

    } catch (e) {
        yield put(ACTIONS.getValidateBulkPayment.failure(e))
    }
}

export function* getValidateIndividualPaymentHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getValidateIndividualPayment.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.ValidateIndividualPayment)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.ValidateIndividualPayment)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }

        const data = yield call(paymentApi.getValidateIndividualPayment, query)
        yield put(ACTIONS.getValidateIndividualPayment.success({ data, ...query }))

    } catch (e) {
        yield put(ACTIONS.getValidateIndividualPayment.failure(e))
    }
}

export function* getValidateWpsPaymentsHandler(action) {
    const { page, search } = action.payload
    yield put(ACTIONS.getValidateWpsPayments.request(action.payload))
    try {
        const currPage = yield select(getPaymentsPage, appConstants.payments.type.ValidateWpsPayments)
        const currSearch = yield select(getPaymentsSearch, appConstants.payments.type.ValidateWpsPayments)
        const query = {
            page: page ? page : currPage,
            search: (!!search || search === '') ? search : currSearch,
        }
        const data = yield call(paymentApi.getValidateWpsPayments, query)
        yield put(ACTIONS.getValidateWpsPayments.success({ data, ...query }))

    } catch (e) {
        yield put(ACTIONS.getValidateWpsPayments.failure(e))
    }
}

export function* approveAuthBulkPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.approveAuthBulkPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.approveAuthBulkPayment, fileId)
        yield put(ACTIONS.getAuthBulkPayments())
        yield put(getFileCounts())
        yield put(ACTIONS.approveAuthBulkPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.approveAuthBulkPayment.failure(e))
    }
}

export function* generateSMSMultipleAuthBulkPaymentHandler(action) {
    const { fileIds } = action.payload
    yield put(ACTIONS.generateSMSMultipleAuthBulkPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.generateSMSMultipleAuthBulkPayment, fileIds)
        yield put(ACTIONS.getAuthBulkPayments())
        yield put(getFileCounts())
        yield put(ACTIONS.generateSMSMultipleAuthBulkPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.generateSMSMultipleAuthBulkPayment.failure(e))
    }
}

export function* approveWithSmsCodeBulkHandler(action) {
    const { sid, smsCode } = action.payload
    yield put(ACTIONS.approveWithSmsCodeBulk.request(action.payload))
    try {
        const result = yield call(paymentApi.approveWithSmsCodeBulk, { sid, smsCode })
        yield put(ACTIONS.getAuthBulkPayments())
        yield put(ACTIONS.getBalance())
        yield put(getFileCounts())
        yield put(ACTIONS.approveWithSmsCodeBulk.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.approveWithSmsCodeBulk.failure(e))
    }
}


export function* approveMultpleWithSmsCodeBulkHandler(action) {
    const { sids, smsCode } = action.payload
    yield put(ACTIONS.approveMultpleWithSmsCodeBulk.request(action.payload))
    try {
        const result = yield call(paymentApi.approveMultpleWithSmsCodeBulk, { sids, smsCode })
        yield put(ACTIONS.getAuthBulkPayments())
        yield put(ACTIONS.getBalance())
        yield put(getFileCounts())
        yield put(ACTIONS.approveMultpleWithSmsCodeBulk.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.approveMultpleWithSmsCodeBulk.failure(e))
    }
}

export function* rejectAuthorizePaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.rejectAuthorizePayment.request(action.payload))
    try {
        const result = yield call(paymentApi.rejectAuthorizePayment, fileId)
        yield put(ACTIONS.getAuthBulkPayments())
        yield put(getFileCounts())
        yield put(ACTIONS.rejectAuthorizePayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.rejectAuthorizePayment.failure(e))
    }
}

export function* approveAuthIndividualPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.approveAuthIndividualPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.approveAuthIndividualPayment, fileId)
        yield put(ACTIONS.getAuthIndividualPayments())
        yield put(getFileCounts())
        yield put(ACTIONS.approveAuthIndividualPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.approveAuthIndividualPayment.failure(e))
    }
}

export function* approveAuthIndividualPaymentSmsCodeHandler(action) {
    const { aId, smsCode } = action.payload
    yield put(ACTIONS.approveAuthIndividualPaymentSmsCode.request(action.payload))
    try {
        const data = yield call(paymentApi.approveAuthIndividualPaymentSmsCode, { aId, smsCode })
        yield put(ACTIONS.getAuthIndividualPayments())
        yield put(getFileCounts())
        yield put(ACTIONS.approveAuthIndividualPaymentSmsCode.success({ data }))

    } catch (e) {
        yield put(ACTIONS.approveAuthIndividualPaymentSmsCode.failure(e))
    }
}

export function* rejectAuthorizeIndividualPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.rejectAuthorizeIndividualPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.rejectAuthorizeIndividualPayment, fileId)
        yield put(ACTIONS.getAuthIndividualPayments())
        yield put(getFileCounts())
        yield put(ACTIONS.rejectAuthorizeIndividualPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.rejectAuthorizeIndividualPayment.failure(e))
    }
}

export function* approveAuthWPSPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.approveAuthWPSPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.approveAuthWPSPayment, fileId)
        yield put(ACTIONS.getAuthWpsPayments())
        yield put(ACTIONS.getBalance())
        yield put(getFileCounts())
        yield put(ACTIONS.approveAuthWPSPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.approveAuthWPSPayment.failure(e))
    }
}

export function* rejectAuthWPSPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.rejectAuthWPSPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.rejectAuthWPSPayment, fileId)
        yield put(ACTIONS.getAuthWpsPayments())
        yield put(ACTIONS.getBalance())
        yield put(getFileCounts())
        yield put(ACTIONS.rejectAuthWPSPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.rejectAuthWPSPayment.failure(e))
    }
}

export function* validateValidBulkPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.validateValidBulkPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.validateValidBulkPayment, fileId)
        yield put(ACTIONS.getValidateBulkPayment())
        yield put(getFileCounts())
        yield put(ACTIONS.validateValidBulkPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.validateValidBulkPayment.failure(e))
    }
}

export function* rejectValidateBulkPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.rejectValidateBulkPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.rejectValidateBulkPayment, fileId)
        yield put(ACTIONS.getValidateBulkPayment())
        yield put(getFileCounts())
        yield put(ACTIONS.rejectValidateBulkPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.rejectValidateBulkPayment.failure(e))
    }
}

export function* validValidateIndividualPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.validValidateIndividualPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.validValidateIndividualPayment, fileId)
        yield put(ACTIONS.getValidateIndividualPayment())
        yield put(getFileCounts())
        yield put(ACTIONS.validValidateIndividualPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.validValidateIndividualPayment.failure(e))
    }
}

export function* rejectValidateIndividualPaymentHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.rejectValidateIndividualPayment.request(action.payload))
    try {
        const result = yield call(paymentApi.rejectValidateIndividualPayment, fileId)
        yield put(ACTIONS.getValidateIndividualPayment())
        yield put(getFileCounts())
        yield put(ACTIONS.rejectValidateIndividualPayment.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.rejectValidateIndividualPayment.failure(e))
    }
}

export function* validValidateWpsBulkHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.validValidateWpsBulk.request(action.payload))
    try {
        const result = yield call(paymentApi.validValidateWpsBulk, fileId)
        yield put(ACTIONS.getValidateWpsPayments())
        yield put(ACTIONS.getBalance())
        yield put(getFileCounts())
        yield put(ACTIONS.validValidateWpsBulk.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.validValidateWpsBulk.failure(e))
    }
}

export function* rejectValidateWpsBulkHandler(action) {
    const { fileId } = action.payload
    yield put(ACTIONS.rejectValidateWpsBulk.request(action.payload))
    try {
        const result = yield call(paymentApi.rejectValidateWpsBulk, fileId)
        yield put(ACTIONS.getValidateWpsPayments())
        yield put(ACTIONS.getBalance())
        yield put(getFileCounts())
        yield put(ACTIONS.rejectValidateWpsBulk.success(action.payload))

    } catch (e) {
        yield put(ACTIONS.rejectValidateWpsBulk.failure(e))
    }
}

export function* watchPaymentsSaga() {
    yield all([
        takeEvery(ACTIONS.getBalance.TRIGGER, getBalanceHandler),
        takeEvery(ACTIONS.getWpsStatus.TRIGGER, getWpsStatusHandler),
        takeEvery(ACTIONS.getStatusReport.TRIGGER, getStatusReportHandler),
        takeEvery(ACTIONS.getViewPayments.TRIGGER, getViewPaymentsHandler),

        takeEvery(ACTIONS.getAuthBulkPayments.TRIGGER, getAuthBulkPaymentsHandler),
        takeEvery(ACTIONS.getAuthIndividualPayments.TRIGGER, getAuthIndividualPaymentsHandler),
        takeEvery(ACTIONS.getAuthWpsPayments.TRIGGER, getAuthWpsPaymentsHandler),
        takeEvery(ACTIONS.getValidateBulkPayment.TRIGGER, getValidateBulkPaymentHandler),
        takeEvery(ACTIONS.getValidateIndividualPayment.TRIGGER, getValidateIndividualPaymentHandler),
        takeEvery(ACTIONS.getValidateWpsPayments.TRIGGER, getValidateWpsPaymentsHandler),

        takeEvery(ACTIONS.approveAuthBulkPayment.TRIGGER, approveAuthBulkPaymentHandler),
        takeEvery(ACTIONS.generateSMSMultipleAuthBulkPayment.TRIGGER, generateSMSMultipleAuthBulkPaymentHandler),
        takeEvery(ACTIONS.approveWithSmsCodeBulk.TRIGGER, approveWithSmsCodeBulkHandler),
        takeEvery(ACTIONS.approveMultpleWithSmsCodeBulk.TRIGGER, approveMultpleWithSmsCodeBulkHandler),
        takeEvery(ACTIONS.rejectAuthorizePayment.TRIGGER, rejectAuthorizePaymentHandler),
        takeEvery(ACTIONS.approveAuthIndividualPayment.TRIGGER, approveAuthIndividualPaymentHandler),
        takeEvery(ACTIONS.approveAuthIndividualPaymentSmsCode.TRIGGER, approveAuthIndividualPaymentSmsCodeHandler),
        takeEvery(ACTIONS.rejectAuthorizeIndividualPayment.TRIGGER, rejectAuthorizeIndividualPaymentHandler),
        takeEvery(ACTIONS.approveAuthWPSPayment.TRIGGER, approveAuthWPSPaymentHandler),
        takeEvery(ACTIONS.rejectAuthWPSPayment.TRIGGER, rejectAuthWPSPaymentHandler),

        takeEvery(ACTIONS.validateValidBulkPayment.TRIGGER, validateValidBulkPaymentHandler),
        takeEvery(ACTIONS.rejectValidateBulkPayment.TRIGGER, rejectValidateBulkPaymentHandler),
        takeEvery(ACTIONS.validValidateIndividualPayment.TRIGGER, validValidateIndividualPaymentHandler),
        takeEvery(ACTIONS.rejectValidateIndividualPayment.TRIGGER, rejectValidateIndividualPaymentHandler),
        takeEvery(ACTIONS.validValidateWpsBulk.TRIGGER, validValidateWpsBulkHandler),
        takeEvery(ACTIONS.rejectValidateWpsBulk.TRIGGER, rejectValidateWpsBulkHandler),

    ])
}
