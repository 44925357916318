import { createActionsCreator } from '../../actionCreatorsFactory'

const createAction = createActionsCreator('SAGAS/PAYROLL/PAYMENT')

export const getBalance = createAction('GET_BALANCE')

export const getWpsStatus = createAction('GET_WPS_STATUS')
export const getStatusReport = createAction('GET_STATUS_REPOST')
export const getViewPayments = createAction('GET_VIEW_PAYMENTS')
export const clearData = createAction('CLEAR_DATA')

export const getAuthBulkPayments = createAction('GET_AUTH_BULK_PAYMENT')
export const approveAuthBulkPayment = createAction('APPROVE_AUTH_BULK_PAYMENT')
export const generateSMSMultipleAuthBulkPayment = createAction('GENERATE_SMS_MULTIPLE_AUTH_BULK_PAYMENT')
export const approveWithSmsCodeBulk = createAction('APPROVE_AUTH_BULK_SMS')
export const approveMultpleWithSmsCodeBulk = createAction('APPROVE_MULTIPLE_AUTH_BULK_SMS')
export const rejectAuthorizePayment = createAction('REJECT_AUTH_PAYMENT')



export const getAuthIndividualPayments = createAction('GET_AUTH_INDIVIDUAL_PAYMENTS')
export const approveAuthIndividualPayment = createAction('APPROVE_AUTH_INDIVIDUAL_PAYMENT')
export const approveAuthIndividualPaymentSmsCode = createAction('APPROVE_AUTH_INDIVIDUAL_PAYMENT_SMS')
export const rejectAuthorizeIndividualPayment = createAction('REJECT_AUTH_INDIVIDUAL_PAYMENT')

export const getAuthWpsPayments = createAction('GET_AUTH_WPS_PAYMENTS')
export const approveAuthWPSPayment = createAction('APPROVE_AUTH_WPS_PAYMENT')
export const rejectAuthWPSPayment = createAction('REJECT_AUTH_WPS_PAYMENT')

export const getValidateBulkPayment = createAction('GET_VALIDATE_BULK_PAYMENTS')
export const validateValidBulkPayment = createAction('VALIDATE_BULK_PAYMENT')
export const rejectValidateBulkPayment = createAction('REJECT_VALIDATE_BULK_PAYMENT')

export const getValidateIndividualPayment = createAction('GET_VALIDATE_INDIVIDUAL_PAYMENTS')
export const validValidateIndividualPayment = createAction('VALIDATE_INDIVIDUAL_PAYMENT')
export const rejectValidateIndividualPayment = createAction('REJECT_VALIDATE_INDIVIDUAL_PAYMENT')

export const getValidateWpsPayments = createAction('GET_VALIDATE_WPS_BULK_PAYMENTS')
export const validValidateWpsBulk = createAction('VALIDATE_WPS_BULK_PAYMENT')
export const rejectValidateWpsBulk = createAction('REJECT_VALIDATE_WPS_BULK_PAYMENT')

