import { createSelector } from 'reselect'
import get from 'lodash/get'
import appConstants from '../../config/appConstants'
import { makeApiUrl, makeServerUrl } from '../../services/apis/baseApi'
import moment from 'moment'
import uuid from 'react-uuid'
import { getAuthCurrentUserRole } from '../auth/selectors'

export const getCommonState = createSelector(
    [
        (state) => {
            return get(state, appConstants.store.routes.common)
        },
    ],
    (commonState) => commonState,
)

export const getCommonIsLoading = createSelector([getCommonState], (state) => {
    try {
        return state.isLoading
    } catch (e) {
    }
    return false
})
export const getCommonIsDownloading = createSelector([getCommonState], (state) => {
    try {
        return state.isDownloading
    } catch (e) {
    }
    return false
})

export const getCommonSettings = createSelector([getCommonState], (state) => {
    try {
        return state.settings
    } catch (e) {
    }
    return []
})
export const getCommonCurrentApp = createSelector([getCommonState], (state) => {
    try {
        return state.currentApp
    } catch (e) {
    }
    return null
})

export const getCommonCurrentIsBusiness = createSelector([getCommonCurrentApp], (currentApp) => {
    try {
        return currentApp === appConstants.apps.business
    } catch (e) {
    }
    return null
})
export const getCommonCurrentIsPayroll = createSelector([getCommonCurrentApp], (currentApp) => {
    try {
        return currentApp === appConstants.apps.payroll
    } catch (e) {
    }
    return null
})

export const getCommonCompanyUsers = createSelector([getCommonState], (state) => {
    try {
        return state.companyUsers
    } catch (e) {
    }
    return null
})
export const getCommonRoles = createSelector([getCommonState, getAuthCurrentUserRole], (state, userRoles) => {
    try {
        return userRoles
    } catch (e) {
    }
    return null
})
export const getCommonAllRoles = createSelector([getCommonState], (state) => {
    try {
        return state.allRoles
    } catch (e) {
    }
    return []
})

export const getCommonAllRoleNames = createSelector([getCommonAllRoles], (roles) => {
    try {
        return roles.map(role => role.name)
    } catch (e) {
    }
    return []
})

export const getCommonUserPermissions = createSelector([getCommonRoles], (roles) => {
    try {
        return Object.entries(roles).map((e) => ({ [e[0]]: e[1] }))
    } catch (e) {
    }
    return null
})
export const getCommonUserPermissionsBusiness = createSelector([getCommonAllRoles], (roles) => {
    try {
        const items = [
            { key: 'createUser', label: 'Create user' },//
            { key: 'business', label: 'Business access' },//
            { key: 'businessAdmin', label: 'Settings' },//
            { key: 'viewBusinessCards', label: 'Employee details' },
            { key: 'businessCardControls', label: 'Card control actions' },
            { key: 'uploadTransferProof', label: 'Upload transfer proof' },
            { key: 'approveRejectBusinessTransactions', label: 'Transactions' },
            { key: 'approveRejectBusinessLimitRequests', label: 'Update card limits' },
            { key: 'addUpdateBusinessCards', label: 'Add employees' },
            { key: 'viewClearRejectedTransactions', label: 'Rejections' },
            { key: 'businessSubscriptionCharges', label: 'Billing' },
        ]
        return roles.map((role) => {
            return items.filter(item => role.hasOwnProperty(item.key))
                .map((item) => {
                    return { ...item, value: !!role[item.key] }
                })
        })
    } catch (e) {
    }
    return []
})

export const getCommonUserPermissionsPayroll = createSelector([getCommonAllRoles], (roles) => {
    try {
        const items = [
            { key: 'createUser', label: 'Create user' },
            { key: 'business', label: 'Business access' },
            { key: 'exportEmployeeData', label: 'Export employee data' },
            { key: 'authorizeWPSBulkSalary', label: 'Authorize WPS salary' },
            { key: 'authorizeBulkSalary', label: 'Authorize bulk salary' },
            { key: 'uploadTransferProof', label: 'Upload transfer proof' },
            { key: 'uploadSalaryFile', label: 'Upload salary file' },
            { key: 'uploadWPSSalaryFile', label: 'Upload WPS salary file' },
            { key: 'viewSalaryDetails', label: 'View payment details' },
            { key: 'validateWPSBulkSalary', label: 'Validate WPS salary' },
            { key: 'authorizeIndividualSalary', label: 'Authorize Individual Salary' },
            { key: 'addEmployee', label: 'Add employees' },
            { key: 'updateKybDetails', label: 'Update Kyb Details' },
            { key: 'validateBulkSalary', label: 'Validate Bulk Salary' },
            { key: 'validateIndividualSalary', label: 'Validate Individual Salary' },
            { key: 'validatePayments', label: 'Validate payments' },
            { key: 'unlinkEmployee', label: 'Remove employees' },
        ]
        return roles.map((role) => {
            return items.filter(item => role.hasOwnProperty(item.key)) // the hard-codded permission has to be into response from server
                .map((item) => {
                    return { ...item, value: !!role[item.key] }
                })
        })
    } catch (e) {
    }
    return []
})

export const enabledBusinessPermission = createSelector(
    [
        getCommonRoles,
        (state, permission) => permission,
    ],
    (roles, permission) => {
        try {
            return !!roles[permission]
        } catch (e) {
        }
        return false
    })

export const enabledPayrollPermission = createSelector(
    [
        getCommonRoles,
        (state, permission) => permission,
    ],
    (roles, permission) => {
        try {
            return !!roles[permission]
        } catch (e) {
        }
        return false
    })

export const getCommonUserUserRoleName = createSelector([getCommonRoles], (roles) => {
    try {
        return roles.name
    } catch (e) {
    }
    return null
})
export const getCommonUserId = createSelector([getCommonRoles], (roles) => {
    try {
        return roles._id
    } catch (e) {
    }
    return null
})

export const getCommonCompanyPermissions = createSelector([getCommonState], (state) => {
    try {
        return state.companyPermissions
    } catch (e) {
    }
    return null
})
export const getCommonPrevUser = createSelector([getCommonState], (state) => {
    try {
        return state.prevUser
    } catch (e) {
    }
    return null
})

export const getCommonPrevUserAvatar = createSelector([getCommonPrevUser], (prevUser) => {
    try {
        return prevUser?.avatar ? prevUser?.avatar : null
    } catch (e) {
    }
    return null
})

export const getCommonPrevUserName = createSelector([getCommonPrevUser], (prevUser) => {
    try {
        return prevUser?.name ? prevUser?.name : ''
    } catch (e) {
    }
    return ''
})


//No state selectors

export const getReadyDetailsRoute = createSelector([(userId) => userId, (userId, date) => date], (userId, date) => {
    return appConstants.routes.business.readyDetails.replace(':userId', userId).replace(':date', date)
})

export const getUserRoute = createSelector([(userId) => userId], (userId) => {
    return appConstants.routes.business.user.replace(':userId', userId)
})

export const getRejectionDetailsRoute = createSelector([(userId) => userId, (userId, date) => date], (userId, date) => {
    return appConstants.routes.business.rejectionDetails.replace(':userId', userId).replace(':date', date)
})

export const getTransactionRoute = createSelector([(transactionId) => transactionId], (transactionId) => {
    return appConstants.routes.business.transaction.replace(':id', transactionId)
})

export const getExportAllTransctions = createSelector([(status) => status], (status) => {
    return makeApiUrl(`/business/transactions/aggregated/export${status ? status : ''}`)
})
export const getExportAllTransctionsByStatus = createSelector([(status) => status], (status) => {
    return makeApiUrl(`/business/transactions/aggregated/export?approvalStatus=${status}`)
})

export const downloadTransactionFile = createSelector([ (fileId) => fileId ], (fileId) => {
    return makeServerUrl(`/downloadTransactionFile?fileId=${fileId}`)
})
export const downloadReceiptFile = createSelector([ (transaction) => transaction ], (transaction) => {
    const { _id: id, transactionFor } = transaction
    if (transactionFor === appConstants.transaction.transactionFor.EXPENSE_CARDS_SUBSCRIPTION) {
        return makeApiUrl(`/transaction/${id}/receipt`)
    } else if (transactionFor === appConstants.transaction.transactionFor.WPS_SALARY) {
        return makeApiUrl(`/transaction/${id}/receipt/wps`)
    } else if (transactionFor === appConstants.transaction.transactionFor.SALARY) {
        return makeApiUrl(`/transaction/${id}/receipt/salary`)
    }
    return null
})

export const downloadInvoice = createSelector([(id) => id], (id) => {
    return makeApiUrl(`/transaction/${id}/receipt`)
})
export const downloadCreateTemplate = createSelector([state => state], (state) => {
    return makeServerUrl(`/paymentFile`)
})
export const getReceiptUrl = createSelector([(id) => id, (id, receipt) => receipt], (id, receipt) => {
    return makeApiUrl(`/business/transactions/receiptImage?transaction=${id}&key=${receipt}`)
})

export const formatData = createSelector([(data) => data], (data) => {
    return moment(data).format('y-MM-DD HH:mm')
})

export const formatDataShort = createSelector([(data) => data], (data) => {
    const { year, month, createdAt } = data
    if (year && month) {
        return `${year}-${String(month).padStart(2,"0")}`
    }

    if (createdAt) {
        return moment(createdAt).format('y-MM-DD')
    }
    return ''
})

export const formatMonthYear = createSelector([(data) => data], (data) => {
    const month = data.slice(0, 2)
    const year = data.slice(2, 6)
    return `${moment(month, 'MM').format('MMMM')} ${year}`
})

export const formatAmount = createSelector(
    [(data) => data,
        (data, noCurrency) => noCurrency],
    (data, noCurrency) => {

        const result = data.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        if (result.indexOf('.') !== -1) {
            return `${!noCurrency ? 'AED ' : ''}${result}`
        }

        return `${!noCurrency ? 'AED ' : ''}${result}.00`
    })

export const formatMoney = createSelector([(data) => data], (data) => {
    const { displayAmount, transactionCurrency, amount, totalAmount } = data

    if (transactionCurrency) {
        return `${transactionCurrency} ${displayAmount}`
    }
    if (amount) {
        return formatAmount(amount)
    }
    if (totalAmount) {
        return formatAmount(totalAmount)
    }
})
export const formatUserName = createSelector([(data) => data], (data) => {
    const { otherNames, lastName, fullName } = data
    if (fullName) {
        return fullName.toLowerCase()
    }
    return `${otherNames ? otherNames : ''} ${lastName ? lastName : ''}`.toLowerCase()
})

export const formatRowId = createSelector([(data) => data], (/*data*/) => {
    //const { userId, month, year, _id } = data
    return uuid()
})


export const getPayrollExportAllEmployeesByStatus = createSelector([(status) => status], (status) => {
    return makeServerUrl(`/exportEmployees?searchText=&employeeStatus=${status || ''}`)
})


export const getPayrollExportAllPrePrintedEmployeesByStatus = createSelector([(status) => status], (status) => {
    return makeServerUrl(`/exportPrePrintedEmployees?searchText=&employeeStatus=${status || ''}`)
})

export const getPayrollTransactionsFile = createSelector(
    [(startDate) => startDate, (startDate, endDate) => endDate,(startDate, endDate, searchText) => searchText],
    (startDate, endDate,searchText) => {
        let queryString = `?`
        if (searchText) queryString += `&searchText=${searchText}`
        else {
            if (startDate) queryString += `startDate=${startDate}`
            if (endDate) queryString += `&endDate=${endDate}`
        }
        return makeServerUrl(`/exportTransactions${queryString}`)
    },
)

export const getPayrollEmployerTransactionsFile = createSelector([(status) => status], (status) => {
  return makeServerUrl(`/exportEmployerTransactions`)
})

export const getPayrollTransactionStatement = createSelector([
        (dates) => dates],
    (dates) => {
        return makeApiUrl(`/transaction/statement?startDate=${dates.start}&endDate=${dates.end}`)
    })





